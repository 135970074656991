import { useTranslation } from 'next-i18next';
import styles from './index.module.less';

import Image from 'next/image';
import { useState } from 'react';
import ContentShareAlert from '@/components/Common/ShareAlert';
import useScreenSize from '@/lib/hooks/utils/useScreenSize';
const TopContainer = () => {
  const { t } = useTranslation('common');
  const { width } = useScreenSize();
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={styles.topContainer}>
        <Image
          src={'/images/planbowl/home-icon-6.webp'}
          alt={''}
          width={34}
          height={34}
          style={{ marginRight: '5px' }}
        />
        <span className={styles['topContainer-text']}>{t('Helped')}</span>
        <span
          className={styles['topContainer-text']}
          style={{ color: 'var(--app-primary-color)', padding: '0 5px' }}
        >{`1,000+`}</span>
        <span className={styles['topContainer-text']}>{t('individuals and families!')}</span>

        {width >= 900 && (
          <>
            <Image
              src={'/images/share/header-share-icon.webp'}
              alt={''}
              width={26}
              height={26}
              style={{ marginLeft: '10px' }}
            />
            <span style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
              {t('Share')}
            </span>
          </>
        )}
      </div>

      <ContentShareAlert
        openType={open}
        handleCancel={() => setOpen(false)}
        shareUrl={process.env.NEXT_APP_WEB_HOST}
        description="Planbowl: More than a Preparedness Guide, it’s an investment in the safety of you and your loved ones."
        shareImage={`https://res-gpts.pumpsoul.com/img/share_img.webp`}
      />
    </>
  );
};

export default TopContainer;
